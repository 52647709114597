<template>
  <section>
    <v-row class="mt-10 px-5">
      <h2 class="mullerFont" style="font-size: 1.5rem">LES PRODUITS COMMERCIALISÉS</h2>
    </v-row>
    <v-divider class="mt-3 mb-6"></v-divider>

    <v-row class="mb-5 text-center">
      <v-col class="d-flex" cols="12" md="4">
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column rounded-xl" link v-bind="attrs" v-on="on" outlined elevation="3">
              <v-card-title></v-card-title>
              <v-row align="center"
                ><v-card-text>
                  <v-icon size="48" color="EoleBlue">mdi-plus-circle</v-icon>
                  <p class="text-h6">Ajouter un produit</p>
                </v-card-text>
              </v-row>
            </v-card>
          </template>

          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text v-if="alreadyExist">
              <v-form ref="form" lazy-validation>
                <div v-if="alreadyExist">
                  <p>Veuillez sélectionner un produit</p>
                  <v-select
                    label="Produits existants"
                    v-model="productSelected"
                    outlined
                    :items="productsProposed"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </div>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="warning"
                text
                @click="
                  close()
                  alreadyExist = true
                "
                >Annuler</v-btn
              >
              <v-spacer></v-spacer>

              <v-spacer> </v-spacer>
              <v-btn
                v-if="alreadyExist"
                :disabled="!alreadyExist || productSelected === null"
                class="white--text"
                color="EoleBlue"
                @click="postAgencyProduct(productSelected)"
                >Ajouter ce nouveau produit</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog></v-col
      >
      <v-col class="d-flex" cols="12" md="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column rounded-xl" v-bind="attrs" v-on="on" outlined elevation="3">
              <v-skeleton-loader
                v-if="products.length === 0"
                class="pa-5"
                width=""
                type="paragraph"
              ></v-skeleton-loader>
              <div v-else>
                <v-card-title class="text-center justify-center">{{ products.length }}</v-card-title>
                <v-row v-if="products.length > 0" align="center"
                  ><v-card-text>
                    <i class="fa-solid fa-circle-check EoleGreen--text fa-3x"></i>
                    <p class="text-h6">Produits configurés actifs</p>
                  </v-card-text> </v-row
                ><v-row v-else align="center">
                  <v-card-text>
                    <p>
                      Vous n'avez pour le moment pas de produits actifs. Pour en ajouter, cliquez sur le bouton à gauche
                      de cette information.
                    </p>
                  </v-card-text>
                </v-row>
              </div>
            </v-card>
          </template>
          <span>Les produits actifs correspondent aux produits que vous avez configuré.</span>
        </v-tooltip>
      </v-col>
      <v-col class="d-flex" cols="12" md="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column rounded-xl" v-bind="attrs" v-on="on" outlined elevation="3">
              <v-skeleton-loader
                v-if="products.length === 0"
                class="pa-5"
                width=""
                type="paragraph"
              ></v-skeleton-loader>
              <div v-else>
                <v-card-title class="text-center justify-center">{{ productsSponsoring.length }}</v-card-title>

                <v-row v-if="productsSponsoring.length > 0" align="center"
                  ><v-card-text>
                    <v-badge
                      icon="mdi-check"
                      color="EoleGreen"
                      class="white--text"
                      bottom
                      overlap
                      offset-x="15"
                      offset-y="15"
                    >
                      <i class="fa-solid fa-handshake EoleBlue--text fa-3x"></i>
                    </v-badge>
                    <p class="text-h6">Produits disponible au parrainage</p>
                  </v-card-text> </v-row
                ><v-row v-else align="center">
                  <v-card-text>
                    <p>
                      Vous n'avez pour le moment pas de produits actifs. Pour en ajouter, cliquez sur le bouton à gauche
                      de cette information.
                    </p>
                  </v-card-text>
                </v-row>
              </div>
            </v-card>
          </template>
          <span>Les produits actifs correspondent aux produits que vous avez configuré.</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row align="center" class="mb-n7">
      <v-col cols="12" md="3">
        <v-text-field v-model="selectedProduct" label="Rechercher"></v-text-field>
      </v-col>
      <v-col cols="12" md="1" align-self="center"> </v-col>
      <v-col cols="12" md="3" align-self="end" class="ml-5"> </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :search="selectedProduct"
          class="elevation-1"
          item-key="id"
          :loading="loading"
          loading-text="Chargement des produits en cours... Merci de patienter"
          :page.sync="page"
          :items-per-page="-1"
          :headers="headers"
          :items="products"
          hide-default-footer
          @page-count="pageCount = $event"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          show-expand
          :no-results-text="`Aucune donnée trouvée ayant le mot clé ${this.search}`"
          sort-by="calories"
          :footer-props="{
            'items-per-page-text': 'produits par page(s)',
          }"
          :custom-filter="filterOnlyCapsText"
        >
          <template v-slot:top>
            <v-dialog v-model="dialogAddCompanyToProduct" persistent max-width="800px">
              <v-card>
                <v-card-title>
                  <span class="text-h5"> <v-icon>mdi-pencil</v-icon> Modification du produit</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-divider class="mb-5"></v-divider>
                    <v-alert type="info" dense color="EoleBlue" prominent text
                      >En selectionnant une compagnie ci-dessous vous pouvez l'ajouter à la liste des compagnies
                      proposant le produit {{ editedItem.name }}</v-alert
                    >
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-select
                          :label="`Compagnie à ajouter à la liste du produit ${editedItem.name}`"
                          v-model="companyToAdd"
                          outlined
                          :items="companyAvailable"
                          item-text="name"
                          item-value="id"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn class="pa-5" color="warning" text @click="close">Annuler</v-btn>
                  <v-btn class="white--text pa-5" color="EoleBlue" @click="postProductLinked(editedItem, companyToAdd)">
                    <v-icon left> mdi-pencil </v-icon>Ajouter la compagnie au produit {{ editedItem.name }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDelete" persistent max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="text-h5"> <v-icon>mdi-delete</v-icon> Supression du produit</span>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text>
                  <v-container>
                    <h3>Êtes-vous sûr de vouloir supprimer le produit : {{ editedItem.name }} ?</h3>
                    <p>
                      En supprimant ce produit, il ne sera plus proposé lors de la conception de la Lettre de Devoir de
                      Conseil (LDDC)
                    </p>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="EoleYellow" text @click="closeDelete">Annuler</v-btn>
                  <v-btn class="white--text" color="EoleError" @click="deleteItemConfirm"
                    >Confirmer la suppression</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu right transition="slide-x-transition" close-on-content-click>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item link>
                  <v-dialog v-model="item.dialogEdit" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-title v-bind="attrs" v-on="on"
                        ><v-icon left>mdi-pencil</v-icon>Modifier</v-list-item-title
                      >
                    </template>

                    <v-card>
                      <v-card-text class="no-padding"> </v-card-text>
                      <v-list subheader two-line>
                        <v-subheader>Typologie du produit {{ item.name }}</v-subheader>
                        <v-list-item-group
                          v-model="item.settings"
                          multiple
                          active-class="EoleBlueLighten EoleBlue--text"
                        >
                          <v-list-item>
                            <template v-slot:default="{ active }">
                              <v-list-item-action>
                                <v-checkbox :input-value="active" color="EoleBlue"></v-checkbox>
                              </v-list-item-action>

                              <v-list-item-content>
                                <v-list-item-title>Professionnel</v-list-item-title>
                                <v-list-item-subtitle v-if="active"
                                  >Le produit {{ item.name }} est pour les professionnels</v-list-item-subtitle
                                >
                                <v-list-item-subtitle v-else
                                  >Le produit {{ item.name }} n'est pas pour les professionnels</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </template>
                          </v-list-item>

                          <v-list-item>
                            <template v-slot:default="{ active }">
                              <v-list-item-action>
                                <v-checkbox :input-value="active" color="primary"></v-checkbox>
                              </v-list-item-action>

                              <v-list-item-content>
                                <v-list-item-title>Particulier</v-list-item-title>
                                <v-list-item-subtitle v-if="active"
                                  >Le produit {{ item.name }} est pour les particuliers</v-list-item-subtitle
                                >
                                <v-list-item-subtitle v-else
                                  >Le produit {{ item.name }} n'est pas pour les particuliers</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <v-card-actions>
                        <v-btn color="transparent" elevation="0" @click="item.dialogEdit = false">Annuler</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn class="white--text" color="EoleGreen" @click="putProductTypology(item)"
                          >Sauvegarder</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-list-item>
                <v-list-item link :href="`/services/products/items?id=${item.id}`" target="blank">
                  <v-list-item-title><v-icon left>mdi-eye</v-icon>Visualiser</v-list-item-title>
                </v-list-item>
                <v-list-item link>
                  <v-dialog
                    v-model="item.dialogEditComparator"
                    max-width="1400px"
                    @click:outside="item.dialogEditComparator = false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-title v-bind="attrs" v-on="on"
                        ><v-icon left>mdi-cogs</v-icon>Paramétrer</v-list-item-title
                      >
                    </template>

                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{ item.name }}</span>
                      </v-card-title>
                      <v-card-text class="no-padding">
                        <v-stepper class="transparent-bg" v-model="editStepper" flat>
                          <v-stepper-content :step="1" class="text-center transparent-bg">
                            <v-card-subtitle>Quelle(s) typologie(s) souhaitez-vous modifier ?</v-card-subtitle>
                            <v-row class="text-center align-center d-inline-flex row-study mb-5 mt-n2">
                              <v-col
                                cols="12"
                                :md="item.particular === 1 && item.professionnal === 0 ? '12' : '6'"
                                v-if="item.particular === 1"
                              >
                                <v-card
                                  @mouseover="editChoice[0].hovered = true"
                                  @mouseleave="editChoice[0].hovered = false"
                                  @click="editStepper = 2"
                                  outlined
                                  width="200"
                                  height="200"
                                  elevation="3"
                                  link
                                  class="align-center d-flex rounded-xl"
                                >
                                  <v-card-text class="text-center pa-5">
                                    <i
                                      :class="[
                                        'mt-4 fa-solid fa-pen-to-square fa-5x',
                                        editChoice[0].hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                                      ]"
                                    ></i>

                                    <p class="mt-2">Modifier le resumé de garantie pour la partie particulier</p>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                              <v-col
                                cols="12"
                                :md="item.professionnal === 1 && item.particular === 0 ? '12' : '6'"
                                v-if="item.professionnal === 1"
                              >
                                <v-card
                                  @mouseover="editChoice[1].hovered = true"
                                  @mouseleave="editChoice[1].hovered = false"
                                  @click="editStepper = 3"
                                  outlined
                                  width="200"
                                  height="200"
                                  elevation="3"
                                  link
                                  class="align-center d-flex rounded-xl"
                                >
                                  <v-card-text class="text-center pa-5">
                                    <i
                                      :class="[
                                        'mt-4 fa-solid fa-pen-to-square fa-5x',
                                        editChoice[1].hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                                      ]"
                                    ></i>

                                    <p class="mt-2">Modifier le resumé de garantie pour la partie professionnelle</p>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-stepper-content>

                          <v-stepper-content
                            v-if="item.particular === 1"
                            :step="2"
                            class="transparent-bg no-padding mb-5"
                          >
                            <v-card-subtitle
                              >Ce tableau concerne la partie particulier du produit {{ item.name }} vous pouvez ajouter
                              un ou plusieurs focus sur ce produit en cliquant sur le bouton « + ».
                            </v-card-subtitle>
                            <v-simple-table dense>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">Focus sur le produit</th>
                                    <th class="text-left"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(guarantee, index) in item.particular_informations" v-bind:key="index">
                                    <td class="text-left">
                                      <v-text-field
                                        style="width: 20%"
                                        :id="`input-particular-${index}`"
                                        ref="newTextField"
                                        outlined
                                        class="mt-6"
                                        dense
                                        v-model="guarantee.name"
                                        label="Saisir texte ici"
                                        single-line
                                      ></v-text-field>
                                    </td>
                                    <td>
                                      <v-btn icon @click="deleteParticularInformations(item, index)" color="EoleError"
                                        ><v-icon>mdi-delete</v-icon></v-btn
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                            <v-row>
                              <v-col cols="12" md="4">
                                <v-btn
                                  style="text-transform: none"
                                  elevation="0"
                                  v-if="item.particular === 1"
                                  @click="addLine(item.particular_informations, item.insurers.length, 'particular')"
                                >
                                  <v-icon left>mdi-plus-circle</v-icon> Ajouter un champs
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-stepper-content>

                          <v-stepper-content
                            v-if="item.professionnal === 1"
                            :step="3"
                            class="transparent-bg no-padding mb-5"
                          >
                            <v-card-subtitle
                              >Ce tableau concerne la partie professionnelle du produit {{ item.name }} vous pouvez
                              ajouter un ou plusieurs focus sur ce produit en cliquant sur le bouton « + ».
                            </v-card-subtitle>
                            <v-simple-table>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">Focus sur le produit</th>
                                    <th class="text-left"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(guarantee, index) in item.professionnal_informations" v-bind:key="index">
                                    <td class="text-left">
                                      <v-text-field
                                        style="width: 20%"
                                        :id="`input-professionnal-${index}`"
                                        ref="newTextField"
                                        outlined
                                        class="my-5 w-10 mt-10"
                                        dense
                                        v-model="guarantee.name"
                                        label="Saisir texte ici"
                                        single-line
                                      ></v-text-field>
                                    </td>
                                    <td>
                                      <v-btn
                                        icon
                                        @click="deleteProfessionnalInformations(item, index)"
                                        color="EoleError"
                                        ><v-icon>mdi-delete</v-icon></v-btn
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                            <v-row>
                              <v-col cols="12" md="4">
                                <v-btn
                                  style="text-transform: none"
                                  elevation="0"
                                  v-if="item.professionnal === 1"
                                  @click="
                                    addLine(item.professionnal_informations, item.insurers.length, 'professionnal')
                                  "
                                >
                                  <v-icon left>mdi-plus-circle</v-icon> Ajouter un champs
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-stepper-content>
                        </v-stepper>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          elevation="0"
                          v-if="editStepper > 1"
                          @click="item.professionnal === 1 ? (editStepper = 1) : editStepper--"
                          >Précédent</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn class="white--text" color="EoleGreen" @click="pushInformation(item.id, item)"
                          >Sauvegarder</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-list-item>
                <v-list-item link @click="deleteItem(item)" target="blank">
                  <v-list-item-title
                    ><v-icon left color="EoleError">mdi-delete</v-icon>Supprimer le produit</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:item.sponsoring="{ item }">
            <td class="text-right justify-end mx-auto">
              <div class="d-flex justify-end">
                <v-switch
                  inset
                  v-model="item.sponsoring"
                  v-on:change="activeProgramForProduct(item.id, item.sponsoring)"
                ></v-switch>
              </div>
            </td>
          </template>
          <template v-slot:no-data>
            <v-alert color="error" text class="ma-2">
              <div class="d-flex align-start">
                <v-icon color="error"> mdi-alert-circle-outline </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    Aucun produit, vous pouvez en ajouter un en cliquant sur le bouton ci-dessus
                  </p>
                </div>
              </div>
            </v-alert>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td class="pa-5" :colspan="headers.length">
              <p>
                Pour les produits <strong>{{ item.name }}</strong> nous travaillons avec les compagnies suivantes :
              </p>
              <v-row class="my-2">
                <v-col
                  class="d-flex text-center"
                  v-for="(partner, indexInsurer) in item.insurers"
                  :key="partner.id"
                  cols="12"
                  md="2"
                >
                  <v-card
                    :title="`${partner.name}`"
                    outlined
                    target="_blank"
                    link
                    class="text-center justify-center flex d-flex flex-column"
                  >
                    <v-card-text>{{ partner.name }} </v-card-text>
                    <v-card-text class="flex-grow-1">
                      <v-img
                        alt="test"
                        class="mx-auto my-2"
                        max-height="80"
                        max-width="80"
                        :src="`https://logo.clearbit.com/https://${partner.website_url}?size=128`"
                      ></v-img>
                    </v-card-text>
                    <v-card-actions class="text-center justify-center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              deleteInformations(item, indexInsurer)
                              deleteInsurerForProduct(partner.id, item.id)
                            "
                            icon
                            color="EoleError"
                            ><v-icon>mdi-delete</v-icon></v-btn
                          >
                        </template>
                        <span
                          >Retirer la compagnie {{ partner.name }} de la liste des compagnies proposant le produit
                          {{ item.name }}
                        </span>
                      </v-tooltip>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12" md="2" class="d-flex text-center">
                  <v-card
                    @click="editItem(item)"
                    outlined
                    target="_blank"
                    link
                    class="text-center justify-center flex d-flex flex-column"
                  >
                    <v-card-text>
                      <v-icon size="64" color="EoleBlue">mdi-plus-circle</v-icon>
                    </v-card-text>
                    <v-card-text>Ajouter une compagnie</v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
      {{ page }}
    </div>
  </section>
</template>

<script>
import Vue from "vue"
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"
import { getCookie } from "@/utils/cookies"

Vue.use(Toast, { maxToasts: 2, newestOnTop: true })
export default {
  name: "Products",
  data: () => ({
    selectedProduct: "",
    productSelected: null,
    alreadyExist: true,
    productsProposed: [],
    editStepper: 1,
    editChoice: [
      {
        text: "Modifier le resumé de garantie pour la partie particulier",
        icon: "fa-pen-to-square",
        value: 2,
        hovered: false,
      },
      {
        text: "Modifier le resumé de garantie pour la partie professionnelle",
        icon: "fa-pen-to-square",
        value: 3,
        hovered: false,
      },
    ],
    test: [],
    settings: [],
    dialogEdit: [],
    dialogEditComparator: [],
    productsSponsoring: [],
    dialogAddCompanyToProduct: null,
    status: [
      {
        id: 1,
        name: "Particulier",
      },
      {
        id: 2,
        name: "Professionnel",
      },
    ],
    clientTypology: {
      id: 1,
      name: "Particulier",
    },
    companyAvailable: [],
    companyToAdd: null,
    search: "",
    expanded: [],
    singleExpand: true,
    page: 1,
    pageCount: 0,
    loading: false,
    insurers: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Nom du produit",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Typologie", value: "typologies", sortable: true },
      { text: "Actions", value: "actions", sortable: false },
      {
        text: "Parrainage",
        align: "right",
        value: "sponsoring",
      },
    ],
    products: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      id: null,
      insurersNames: [],
      clientTypology: null,
      idClientTypology: 1,
      settings: [],
      sponsoring: false,
    },
    defaultItem: {
      name: "",
      id: null,
      insurersNames: [],
      clientTypology: null,
    },
    savePage: 1,
  }),
  props: ["e2"],

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nouveau produit" : "Modification compagnie"
    },
  },

  watch: {
    e2(value) {
      alert(value)
      console.log(value)
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  async created() {
    this.agency = localStorage.getItem("agency")

    this.fetchAllProducts()
    this.fetchProducts()
    this.fetchInsurers()
  },

  methods: {
    async putProductTypology(item) {
      this.savePage = this.page
      let idAgency = this.$store.state.user.agency_id
      let professionnal = 0
      let particular = 0

      item.settings.includes(0) ? (professionnal = 1) : (professionnal = 0)
      item.settings.includes(1) ? (particular = 1) : (particular = 0)

      try {
        let response
        response = await fetch(
          `${config.apiUri}/php/pages/products.php?idAgency=${idAgency}&id=${item.id}&professionnal=${professionnal}&particular=${particular}`,
          {
            mode: "cors",
            method: "PUT",
          },
        ).finally(() => {
          this.initialize()
          this.$toast.success(`Vous avez modifié le produit ${item.id}`, {
            position: "bottom-right",
            timeout: 3000,
          })
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de supprimer le produit ${item.id}`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    deleteParticularInformations(item, index) {
      item.particular_informations.splice(index, 1)
    },
    deleteProfessionnalInformations(item, index) {
      item.professionnal_informations.splice(index, 1)
    },
    deleteInformations(item, index) {
      console.log(item, index)

      if (parseInt(item.particular) === 1) {
        item.particular_informations.forEach(line => {
          line.valueInsurer.splice(index, 1)
        })
      }

      if (parseInt(item.professionnal) === 1) {
        item.professionnal_informations.forEach(line => {
          line.valueInsurer.splice(index, 1)
        })
      }

      // this.pushInformation(item.id, item)
    },
    async deleteProduct(idAgencyProduct, idProduct) {
      let idAgency = this.$store.state.user.agency_id
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        await fetch(`${config.apiUri}/agencies/${idAgency}/products/${idAgencyProduct}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        })

        this.$toast.success(`Vous avez supprimé le produit ${this.editedItem.name}`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de supprimer le produit ${this.editedItem.name}`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async postAgencyProduct(idProduct) {
      let existProductInProducts = false

      this.products.forEach(product => {
        if (parseInt(product.id_product) === parseInt(idProduct)) {
          existProductInProducts = true
        }
      })
      this.savePage = this.page

      let idAgency = this.$store.state.user.agency_id

      const postData = {
        idProduct: idProduct,
      }

      if (!existProductInProducts) {
        try {
          await fetch(`${config.apiUri}/agencies/${idAgency}/products`, {
            mode: "cors",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${getCookie("jwt")}`,
            },
            body: JSON.stringify(postData),
          }).finally(() => {
            this.$toast.success(`Vous avez ajouté un nouveau produit`, {
              position: "bottom-right",
              timeout: 3000,
            })
            this.initialize()
          })
        } catch (e) {
          console.log(e)
          this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter une nouvelle compagnie à ce produit.`, {
            position: "bottom-right",
            timeout: 3000,
          })
        }
      } else {
        this.$toast.error(`Ce produit est déjà présent dans votre liste de produit.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async post(item) {
      this.savePage = this.page

      let idAgency = this.$store.state.user.agency_id
      let professionnal = 0
      let particular = 0

      item.settings.includes(0) ? (professionnal = 1) : (professionnal = 0)
      item.settings.includes(1) ? (particular = 1) : (particular = 0)
      item.sponsoring ? (item.sponsoring = 1) : (item.sponsoring = 0)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/php/pages/products.php?productName=${item.name}&idAgency=${idAgency}&sponsoring=${item.sponsoring}&professionnal=${professionnal}&particular=${particular}`,
          {
            mode: "cors",
            method: "POST",
            headers: headers,
            body: JSON.stringify(item),
          },
        ).finally(() => {
          this.$toast.success(`Vous avez ajouté un nouveau produit`, {
            position: "bottom-right",
            timeout: 3000,
          })
          this.initialize()
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter une nouvelle compagnie à ce produit.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async activeProgramForProduct(idProduct, idSponsoring) {
      this.savePage = this.page
      let idAgency = this.$store.state.user.agency_id
      idSponsoring ? (idSponsoring = 1) : (idSponsoring = 0)
      try {
        fetch(`${config.apiUri}/agencies/${idAgency}/products/${idProduct}/activeProgram/${idSponsoring}`, {
          mode: "cors",
          method: "PUT",
        }).finally(() => {
          this.initialize()
          this.$toast.info(`Le parrainage de la compagnie à été activé.`, {
            position: "bottom-right",
            timeout: 3000,
          })
        })
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de supprimer le compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    pushInformation(idProduct, item) {
      this.savePage = this.page

      console.log("push")
      if (item.particular === 1) {
        console.log("SendParticular")

        this.putInformations(idProduct, item.particular_informations, "particular")
      }

      if (item.professionnal === 1) {
        this.putInformations(idProduct, item.professionnal_informations, "professionnal")
        console.log("SendProfessionnal")
      }
    },
    async putInformations(idProduct, data, typology) {
      this.savePage = this.page

      let idAgency = this.$store.state.user.agency_id

      try {
        fetch(`${config.apiUri}/agencies/${idAgency}/products/${idProduct}/${typology}/comparative`, {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({
            informations: data,
          }),
        })
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    deleteInsurerForProduct(idPartner, idAgencyProduct) {
      this.savePage = this.page

      try {
        const idAgency = this.$store.state.user.agency_id
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        fetch(`${config.apiUri}/agencies/${idAgency}/products/${idAgencyProduct}/partners/${idPartner}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        }).finally(() => {
          this.initialize()
          this.$toast.info(`La compagnie à bien été supprimée du produit.`, {
            position: "bottom-right",
            timeout: 3000,
          })
        })
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de supprimer le compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async postProductLinked(item, idInsurer) {
      const idAgency = this.$store.state.user.agency_id
      this.savePage = this.page

      this.addColumn(item)
      // this.pushInformation(item.id, item)

      let data = {
        idPartner: idInsurer,
      }
      try {
        let existInArray = false
        let headers = new Headers()

        item.insurers.forEach(element => {
          if (element.id === this.companyToAdd) {
            existInArray = true
          }
        })

        if (!existInArray) {
          await fetch(`${config.apiUri}/agencies/${idAgency}/products/${item.id}`, {
            mode: "cors",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${getCookie("jwt")}`,
            },
            body: JSON.stringify(data),
          }).finally(() => {
            this.initialize()
            this.$toast.success(`Vous avez ajouté une nouvelle compagnie à ce produit.`, {
              position: "bottom-right",
              timeout: 3000,
            })
          })
        } else {
          this.$toast.error(`La compagnie que vous souhaitez ajouter propose déjà ce produit.`, {
            position: "bottom-right",
            timeout: 3000,
          })
        }
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter une nouvelle compagnie à ce produit.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async fetchInsurers() {
      this.loading = true
      let agencyId = this.$store.state.user.agency_id
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        const response = await fetch(`${config.apiUri}/agencies/${agencyId}/partners`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.forEach(element => {
          this.insurers.push({
            id: element.partnerId,
            name: element.name,
          })
        })
        this.loading = false
        this.fetch = true
      } catch (e) {
        console.error(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toLowerCase().indexOf(search.toLowerCase()) !== -1
      )
    },
    wip() {
      alert("Work in progress. This button don't work currently")
    },
    async fetchAllProducts() {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        const response = await fetch(`${config.apiUri}/products`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.forEach(element => {
          this.productsProposed.push(element)
        })

        this.productsProposed.forEach(proposed => {
          this.products.forEach(product => {
            if (proposed.id === product.id_product) {
              this.productsProposed.splice(this.productsProposed.indexOf(proposed), 1)
            }
          })
        })
      } catch (e) {
        console.error(e)
        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async fetchProducts() {
      console.log("fetchProducts")
      this.products = []
      this.productsSponsoring = []
      this.loading = true
      let agencyId = this.$store.state.user.agency_id
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        const response = await fetch(`${config.apiUri}/agencies/${agencyId}/products`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (response.ok) {
          data.forEach(element => {
            if (parseInt(element.sponsoring) === 1) {
              this.productsSponsoring.push({
                id: element.id,
                name: element.name,
                sponsoring: parseInt(element.sponsoring),
                professionnal: element.professionnal,
                particular: element.particular,
                insurers: element.insurers,
              })
            }
            let typologies = []
            let settings = []
            if (parseInt(element.particular) === 1) {
              typologies.push("Particulier")
              settings.push(1)
            }
            if (parseInt(element.professionnal) === 1) {
              typologies.push("Professionnel")
              settings.push(0)
            }

            this.products.push({
              id: element.id,
              name: element.name,
              sponsoring: parseInt(element.sponsoring),
              professionnal: parseInt(element.professionnal),
              particular: parseInt(element.particular),
              insurers: element.insurers,
              typologies: typologies,
              particular_informations:
                element.particular_informations != null || "" ? JSON.parse(element.particular_informations) : [],
              professionnal_informations:
                element.professionnal_informations != null || "" ? JSON.parse(element.professionnal_informations) : [],
              settings: settings,
              dialogEdit: false,
              dialogEditComparator: false,
              id_product: parseInt(element.id_product),
            })
          })
        }

        this.loading = false
        this.fetch = true

        this.page = this.savePage
      } catch (e) {
        console.error(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    // async fetchInsurersByProduct() {
    //   this.loading = true

    //   try {
    //     let headers = new Headers()

    //     headers.append("Content-Type", "application/json")
    //     headers.append("Accept", "application/json")
    //     headers.append("Origin", "*")

    //     const response = await fetch(`${config.apiUri}/products`, {
    //       mode: "cors",
    //       method: "GET",
    //       headers: headers,
    //     })
    //     const data = await response.json()

    //     let x = 0
    //     data.forEach(element => {
    //       this.products.push({
    //         name: element.name,
    //         id: element.id,
    //         insurersNames: [],
    //         clientTypology: element.designation,
    //       })
    //       this.fetchProductsById(element.id, x)
    //       x++
    //     })

    //     this.loading = false
    //     this.fetch = true
    //   } catch (e) {
    //     console.log(e)
    //     this.loading = false
    //     this.fetch = false
    //     this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
    //       position: "bottom-right",
    //       timeout: 3000,
    //     })
    //   }
    // },

    // async fetchProductsById(idProduct, indexProduct) {
    //   try {
    //     let headers = new Headers()

    //     headers.append("Content-Type", "application/json")
    //     headers.append("Accept", "application/json")
    //     headers.append("Origin", "*")

    //     const response = await fetch(`${config.apiUri}/products/${idProduct}`, {
    //       mode: "cors",
    //       method: "GET",
    //       headers: headers,
    //     })
    //     const data = await response.json()
    //     let arrayInsurers = []
    //     for (let index = 0; index < data.length; index++) {
    //       arrayInsurers.push({
    //         id: data[index].insurerId,
    //         insurerName: data[index].insurerName,
    //         website: data[index].insurerWebsite,
    //       })
    //       this.products[indexProduct]["insurersNames"] = arrayInsurers
    //     }

    //     arrayInsurers = []
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    updateValue(guaranteeIndex, valueIndex, newValue) {
      this.item.guarantees[guaranteeIndex].valueInsurer[valueIndex] = newValue
    },
    addColumn(item) {
      console.log(item)
      if (item.particular === 1) {
        item.particular_informations.forEach(element => {
          element.valueInsurer.push("")
        })
      }
      if (item.professionnal === 1) {
        item.professionnal_informations.forEach(element => {
          element.valueInsurer.push("")
        })
      }
    },
    addLine(array, insurerLength, typology) {
      let valueInsurer = []
      for (let i = 0; i < insurerLength; i++) {
        valueInsurer.push("")
      }

      array.push({
        name: "",
        valueInsurer: valueInsurer,
      })

      if (typology === "particular") {
        this.$nextTick(() => {
          const inputElement = document.getElementById("input-particular-" + (array.length - 1))
          if (inputElement) {
            inputElement.focus()
          }
        })
      } else {
        this.$nextTick(() => {
          const inputElement = document.getElementById("input-professionnal-" + (array.length - 1))
          if (inputElement) {
            inputElement.focus()
          }
        })
      }
    },
    getLength() {
      return this.products.length
    },
    initialize() {
      // initialize array products to empty array
      this.products = []
      this.fetchProducts()
      this.page = this.savePage
    },
    getDifference(array1, array2) {
      return array1.filter(object1 => {
        return !array2.some(object2 => {
          return object1.id === object2.id
        })
      })
    },
    editItem(item) {
      this.companyAvailable = []
      this.editedIndex = this.products.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.companyAvailable = this.insurers
      this.dialogAddCompanyToProduct = true
    },

    deleteItem(item) {
      this.editedIndex = this.products.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.deleteProduct(this.editedItem.id, this.editedItem.id_product)
      this.products.splice(this.editedIndex, 1)
      this.closeDelete()
      this.waitTimeout = true
    },

    close() {
      this.dialogAddCompanyToProduct = false
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.products[this.editedIndex], this.editedItem)
      } else {
        this.products.push(this.editedItem)
        this.post(this.editedItem)
      }
      this.close()
    },
  },
}
</script>
<style lang="scss" scoped>
.no-padding {
  padding: 0 !important;
}
// .products {
//   //background-color: #001f47;
//   background-image: url("../../../../src/assets/images/services/bgg.png");
//   background-size: cover;
//   padding: 5.5rem;
//   margin-bottom: 20px;
// }
</style>
